/**
 * Research Platform for MChoice Inc.
 * Service: Enable user to quickly pull up compnay level data to perform research
 */

import React, { useState, useEffect } from "react";
import { Paper, InputBase, IconButton } from '@mui/material';
import { Search }  from '@mui/icons-material';


function Research() {
    // initialize search state
    const [searchInput, setSearchInput] = useState("");


    // information cards every time their state is change
    useEffect(() => { 
        console.log(searchInput);
    }, []);


    // handle search input change
    // console log the search for now
    const handleInputChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
    }

    // handle the search button click
    // console log the search for now
    const handleSearchClick = (e) => {
        e.preventDefault();
        console.log(searchInput);
    }



    return (
        <section className="white-section" id="title">
            <div className="container-fluid">
                <div className="flex flex-wrap justify-center mt-10">
                    <Paper component="form" 
                        className="flex justify-center w-1/3 h-15"
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search for a company"
                            inputProps={{ 'aria-label': 'search for a company' }}
                            onChange={handleInputChange}
                        />
                        <IconButton 
                            type="submit" 
                            sx={{ p: '10px' }} 
                            aria-label="search"
                            onClick={(e) => handleSearchClick(e)}
                        >
                            <Search />
                        </IconButton>
                    </Paper>
                </div>
                <div className="flex flex-wrap mt-10">

                </div>
            </div>
        </section>
       
    )
}


export default Research;