/**
 * Overall Navigation Menu bar for MChoice Inc.
 * Enable Visual Navigation for authenticated and unauthenticated users
 */
import React from 'react';
import Footer from "./Footer";


export default function MenuLinks(props) {
    return (
        <>
            <section className="colored-section" id="title">
                <div className="container-fluid">
                    {/* <!-- Nav Bar --> */}
                    <nav className="navbar navbar-expand-lg navbar-dark pb-3">
                        <a className="navbar-brand" href="/">MChoice Inc.</a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        {/* <!-- Establish a list of link for navigation --> */}
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                {!props.isAuthenticated ? 
                                    <li className="nav-item">
                                        <a className="nav-link" href="/company">Company</a>
                                    </li> :
                                    <li className="nav-item">
                                        <a className="nav-link" href="/budget">Management</a>
                                    </li>
                                }
                                {!props.isAuthenticated ? 
                                    <li className="nav-item">
                                        <a className="nav-link" href="#product">Product</a>
                                    </li> :
                                    <li className="nav-item">
                                        <a className="nav-link" href="/research">Research</a>
                                    </li>
                                }
                                {!props.isAuthenticated ? 
                                    <li className="nav-item">
                                        <a href="/login"><button type="button" className="btn btn-outline-light" >Login</button></a>
                                    </li> : null
                                }
                                {!props.isAuthenticated ? 
                                    <li className="nav-item">
                                        <a href="/signup"><button type="button" className="btn btn-dark" href="/signup">Get Started</button></a>
                                    </li> : null
                                }
                                {props.isAuthenticated ?
                                    <li className="nav-item">
                                        <button type="button" className="btn btn-outline-light"  onClick={() => props.logout()}>Logout <i className="fas fa-power-off"></i></button>
                                    </li> : null
                                }
                            </ul>
                        </div>
                    </nav>
                </div>
                {props.renderPage}
            </section>
            <section className="white-section" id="footer">
                <Footer />
            </section>
        </>
    );
}