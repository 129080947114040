import React, { useState } from 'react';
import Sidebar from "./board_components/Sidebar.jsx"
import BoardCard from "./board_components/BoardCard.jsx";

function UserDashboard(props) {

    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (
        <section className="white-section" id="title">
            <div className="container-fluid">
                <div className="flex h-screen overflow-hidden">
                    {/* Sidebar */}
                    <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />


                    {/* Content area */}
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                        {/*  Site header */}
                        <main>
                            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                                {/* Welcome banner */}

                                {/* Dashboard actions */}
                                <div className="sm:flex sm:justify-between sm:items-center mb-8">

                                    {/* Left: Avatars */}

                                    {/* Right: Actions */}
                                    <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

                                    {/* Filter button */}
                                    {/* Datepicker built with flatpickr */}
                                    </div>
                                </div>
                                {/* Cards */}
                                <div className="grid grid-cols-12 gap-6">
                                    {/* Testing Card */}
                                    <BoardCard />
                                    <BoardCard />
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default UserDashboard;