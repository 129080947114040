/**
 * React App Urls router components
 * Implement app routing to multiple page application
 */
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";


import { Home, Login, SignUp, Confirm, Budget, Research, ForgotPassword, UserDashboard, LeadershipTeam } from "./pages";


function Urls(props) {    
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" caseSensitive={false} element={<Home isAuthenticated={props.isAuthenticated} />} />
                    <Route exact path="/login" caseSensitive={false} element={<Login isAuthenticated={props.isAuthenticated} />} />
                    <Route exact path="/signup" caseSensitive={false} element={<SignUp />} />
                    <Route exact path="/company" caseSensitive={false} element={<LeadershipTeam />} />
                    <Route exact path="/forgotpassword" caseSensitive={false} element={<ForgotPassword />} />
                    <Route exact path="/confirm/:id" element={<Confirm />} />

                    <Route path="/budget" caseSensitive={false} 
                        element={ props.isAuthenticated ? <Budget isAuthenticated={props.isAuthenticated} /> : <Home isAuthenticated={props.isAuthenticated} />}
                    />
                    <Route path="/research" caseSensitive={false} 
                        element={ props.isAuthenticated ? <Research isAuthenticated={props.isAuthenticated} /> : <Home isAuthenticated={props.isAuthenticated} />}
                    />
                    <Route path="/user" caseSensitive={false} 
                        element={ props.isAuthenticated ? <UserDashboard isAuthenticated={props.isAuthenticated} /> : <Home isAuthenticated={props.isAuthenticated} />}
                    />
                    {
                    /* <Route path="/opex" caseSensitive={false} 
                        element={ props.isAuthenticated ? <OpEx isAuthenticated={props.isAuthenticated} /> : <Home isAuthenticated={props.isAuthenticated} />}
                    /> */
                    /* <Route exact path="/signup/"> <SignUp {...props} /></Route>
                    <Route exact path='/confirm/:id' component={Confirm} />
                    <PrivateRoute exact path="/budget" isAuthenticated={props.isAuthenticated}> <Budget {...props} /> </PrivateRoute>
                    <PrivateRoute exact path="/port" isAuthenticated={props.isAuthenticated}> <Portfolio {...props} /> </PrivateRoute>
                    <Route exact path="/about/"> <About {...props} /> </Route>
                    <Route exact path="/contact/"> <Contact {...props} /> </Route>
                    <PrivateRoute exact path="/dashboard" isAuthenticated={props.isAuthenticated}> <Dashboard {...props} /> </PrivateRoute>
                    <PrivateRoute exact path="/profile" isAuthenticated={props.isAuthenticated}> <Profile {...props} /> </PrivateRoute>
                    <PrivateRoute exact path="/inspiration" isAuthenticated={props.isAuthenticated}> <Inspiration {...props} /> </PrivateRoute>
                    <Route exact path="/vincenzo"> <Vincenzo {...props} /></Route> */}
                </Routes>
            </BrowserRouter>
        </>
    )
};

export default Urls;