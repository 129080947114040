/**
 * Main App component, handing of rendering each pages according to a specified layout
 */
import React, { useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';
import Urls from './Urls';
import Layout from './Layout';

// redux for authAction implementation
import { connect } from 'react-redux';
import * as actions from "./auth-context/authActions";


function App(props) {

    // Check if the user is authenticated and set the state accordingly
    // Applied when app component is mounted
    // console.log(props.isAuthenticated);
    // console.log("BEFORE");
    // console.log(props.isAuthenticated);

    useEffect(() => {
        props.setAuthenticatedIfRequired();
    }, []);

    // console.log("AFTER");
    // console.log(props.isAuthenticated);

    return (
    <div className="app">
        <Layout {...props}>
            <Urls {...props} />
            <Analytics />
        </Layout>
    </div>
    );
}

// One or more of the redux states in the store are available as props
const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null && state.auth.token !== undefined,
        token: state.auth.token
    }
}

// One or more of the redux actions in the form of dispatch(action) combinations are available as props
const mapDispatchToProps = (dispatch) => {
    return {
        setAuthenticatedIfRequired: () => dispatch(actions.authCheckState()),
        logout: () => dispatch(actions.authLogout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
