let API_SERVER_VAL = '';
//define whether or not you are in development mode or production mode
switch (process.env.NODE_ENV) {
    case 'development':
        API_SERVER_VAL = 'http://localhost:5000';
        break;
    case 'production':
        //API_SERVER_VAL = 'process.env.PUBLIC_URL';
        API_SERVER_VAL = 'https://mchoice-backend.vercel.app';
        break;
    default:
        API_SERVER_VAL = 'http://localhost:5000';
        break;
}
//render the API ports and how long can a user login for 
export const API_SERVER = API_SERVER_VAL;
export const SESSION_DURATION = 5 * 3600 * 1000;
