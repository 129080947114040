/**
 * Application template to store a budget entry
 * Contains: 
 *      - item name
 *      - item cost
 *      - delete item button
 */
import React from "react";
import { Button, TableRow, Card} from '@mui/material';


function Entry(props){
    //handle complex state change
    return (
        <TableRow>
            <td>{props.item}</td>
            <td>{props.cost}</td>
            <td><Button component={Card} onClick={() => props.onClicked(props.id)}> Delete </Button></td>
        </TableRow>
    );
}

export default Entry;