/**
 * Layout Component for the application
 * @version 1.0.0
 * TODO: dynamically change MenuLinks design based on some navigation property
 */

import React from 'react';
import MenuLinks from './pages/page_templates/MenuLinks';

function Layout(props) {
    return (
        <>
            <MenuLinks {...props} renderPage={props.children}/>
        </>
    )
}
export default Layout


