/**
 * Functional Component to handle forgotten password
 * @param UsrCreds: User Provided Credentials 
 */
 import React, { useState, useEffect } from "react";
 import { useNavigate } from "react-router-dom";
 import { Button, Link, TextField } from "@mui/material";
//  import { authLogin, useAuthDispatch } from "../auth-context";
 import MenuLinks from "./page_templates/MenuLinks";
 import axios from 'axios';
 import * as settings from '../../settings';
 
 
 function ForgotPassword(props) {
    // handle local state change
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");


 
     // redirect the user to hompage if the authentication is complete
    let navigate = useNavigate();
    useEffect(() => {
        if (props.isAuthenticated) { navigate("/", { replace: "true" }) };
    });
 
     
    /**
     * Function to handle changes to form field
     * @param {*} event: detectable changes between email/password field
     * @returns: updated email/password state
     */
    const handleFormFieldChange = (event) => {
        switch (event.target.id) {
            case 'email': setEmail(event.target.value.toLowerCase()); break;
            case 'password': setPassword(event.target.value); break;
            case 'password2': setPassword2(event.target.value); break;
            default: return null;
        }
    };

    
    /**
     * Sent form field information to password reset endpoint
     * TODO: add 2FA for password reset
     * @param {*} e: detectable event change
     * @return: alert whether or not user password changes is successful
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        //post axios call
        if (password !== password2) {
            alert("Password do not match!")
        } else{
            if ((email !== "") && (password !== "") && (password2 !== "")) {
                axios.post(`${settings.API_SERVER}/api/users/iforgot`, {
                    email: email.toLowerCase(),
                    password: password,
                    password2: password2
                })
                .then(data => {
                    console.log(data.data.msg);
                    alert(data.data.msg);
                    navigate("/", { replace: "true" });
                })
                .catch(err => {
                    console.log(err);
                    alert(err);
                });
            } else {
                alert("One or more input fields are empty, please check your input!")
            }
        }
    };
 
 
    return(
        <>
            <section className="colored-section" id="title">
                <div className="container-fluid">
                    <MenuLinks isAuthenticated={props.isAuthenticated}/>
                    <div className="flex flex-wrap justify-center mt-20">
                        <div className="w-full max-w-sm">
                            <form className="shadow-md bg-white rounded px-8 pt-6 pb-8 mb-4" 
                                noValidate onSubmit={handleSubmit}>
                                <div className="mb-5">
                                    <label className="block text-gray-700 text-lg font-bold mb-2">Forgot Password</label>
                                    <TextField
                                        id="email"
                                        variant="outlined"
                                        margin="normal"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        required
                                        fullWidth
                                        autoFocus
                                        onChange={handleFormFieldChange}
                                    />
                                <TextField
                                    id="password"
                                    variant="outlined"
                                    margin ="normal"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    required
                                    fullWidth
                                    autoFocus
                                    onChange={handleFormFieldChange}
                                    error={ password !== password2 }
                                    helperText={password !== password2 ? "Password do not match": null}
                                />
                                <TextField
                                    id="password2"
                                    variant="outlined"
                                    margin ="normal"
                                    name="password2"
                                    label="Confirm Password"
                                    type="password"
                                    required
                                    fullWidth
                                    autoFocus
                                    onChange={handleFormFieldChange}
                                    error={password !== password2}
                                    helperText={password !== password2 ? "Password do not match" : null}
                                    />
                                </div>
                                <div className="flex items-center justify-between">
                                    <Button
                                        className="px-4 py-2"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Reset Password
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
        
    );
}
export default ForgotPassword;