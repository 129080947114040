/**
 * Functional Component to handle user login
 * @param UsrCreds: User Provided Credentials 
 */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Link, TextField } from "@mui/material";

// apply redux
import { connect } from 'react-redux';
import * as actions from '../auth-context/authActions';
// import { authLogin, useAuthDispatch } from "../auth-context";


function Login(props) {
    // handle local state change
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);

    // instantiate the custom Hooks
    // hook onto the authentication dispatch actions
    // const dispatch = useAuthDispatch();
    
    // redirect the user to hompage if the authentication is complete
    let navigate = useNavigate();
    useEffect(() => {
        if (props.isAuthenticated) { navigate("/") };
    });

    
    /**
     * Function to handle changes to form field
     * @param {*} event: detectable changes between email/password field
     * @returns: updated email/password state
     */
    const handleFormFieldChange = (event) => {
        switch (event.target.id) {
            case 'email': setEmail(event.target.value.toLowerCase()); break;
            case 'password': setPassword(event.target.value); break;
            default: return null;
        }
    };


    /**
     * Sent form field information to user login api for verification
     * @param {*} e: detectable event change
     * @return: alert whether or not user login successfully
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        if ((email !== null) && (password !== null)) {
            let payload = {
                email: email.toLowerCase(),
                password: password 
            };
                // call onto authLogin action to make a login request 
                // if autLogin is successful, navigate the user to the correct page
                props.onAuth(payload);
        } else {
            alert("Email or Password Field is Empty!");
        }
    }


    return(
        <>
            <section className="colored-section" id="title">
                <div className="container-fluid">
                    {/* <MenuLinks isAuthenticated={props.isAuthenticated}/> */}
                    <div className="flex flex-wrap justify-center mt-20">
                        <div className="w-full max-w-sm">
                            <form className="shadow-md bg-white rounded px-8 pt-6 pb-8 mb-4" 
                                noValidate onSubmit={handleSubmit}>
                                <div className="mb-5">
                                    <label className="block text-gray-700 text-lg font-bold mb-2">Sign In</label>
                                    <TextField
                                        id="email"
                                        variant="outlined"
                                        margin="normal"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        required
                                        fullWidth
                                        autoFocus
                                        onChange={handleFormFieldChange}
                                    />
                                    <TextField
                                        id="password"
                                        variant="outlined"
                                        margin="normal"
                                        name="password"
                                        label="Password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        fullWidth
                                        autoFocus
                                        onChange={handleFormFieldChange}
                                    />
                                </div>
                                <div className="flex items-center justify-between">
                                    <Button
                                        className="px-4 py-2"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Sign In
                                    </Button>
                                    <Link href="/forgotpassword" variant="body2"> Forgot Password?</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
        
    );
}

const mapDispatchToProps = dispatch => {
    return { onAuth: (payload) => dispatch(actions.authLogin(payload)) }
}

export default connect(null, mapDispatchToProps)(Login);