/**
 * Functional Component to handle user signup
 * @param UsrCreds: User Provided Credentials 
 */
import React, { useState } from 'react';
import axios from 'axios';
import { Button, Link, TextField } from "@mui/material";
import * as settings from '../../settings';


function SignUp(props) {

    const [fullName, setFullName] = useState({
        fName: "",
        lName: "",
    });

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    /**
     * Function to handle changes to form field
     * @param {*} event: detectable changes between email/password field
     * @returns: updated email/password state
     */    
    const handleFormFieldChange = (event) => {
        switch (event.target.id) {
            case 'firstName': 
                setFullName((prevValue) => {
                    return {
                        ...prevValue,
                        [event.target.name]: event.target.value
                    };
                }); 
                break;
            case 'lastName': 
                setFullName((prevValue) => {
                    return {
                        ...prevValue,
                        [event.target.name]: event.target.value
                    };
                }); 
                break;
            case 'email': setEmail(event.target.value.toLowerCase()); break;
            case 'password': setPassword(event.target.value); break;
            case 'password2': setPassword2(event.target.value); break;
            default: return null;
        }
    };


    
    // send local state to authentication
    const handleSubmit = (e) => {
        e.preventDefault();
        //post axios call
        if (password !== password2) {
            alert("Password do not match!")
        } else{
            let name = fullName.fName + " " + fullName.lName;
            if ((name !== "") && (email !== "") && (password !== "") && (password2 !== "")) {
                axios.post(`${settings.API_SERVER}/api/users/signup`, {
                    name: name,
                    email: email.toLowerCase(),
                    password: password,
                    password2: password2
                })
                .then(data => {
                    console.log(data.data.msg);
                    alert(data.data.msg);
                    let userId = data.data.id;
                    axios.post(`${settings.API_SERVER}/api/twoFactorAuth/server_send`, {
                        "id": userId,
                        "email": email.toLowerCase()
                    })
                    .then(data=>{console.log(data.data.msg);})
                    .catch(err=>{console.log(err);})
                })
                .catch(err => {
                    console.log(err);
                    alert(err);
                });
            } else {
                alert("One or more signup field is empty, please check your input!")
            }
        }
    };


    return (
        <>
            <section className="colored-section" id="title">
                <div className="container-fluid">
                    <div className="flex flex-wrap justify-center mt-20">
                        <div className="w-full max-w-sm">
                            <form className="shadow-md bg-white rounded px-8 pt-6 pb-8 mb-4" 
                                        noValidate onSubmit={handleSubmit}>
                                <div className="mb-5">
                                    <label className="block text-gray-700 text-lg font-bold mb-2">Sign Up</label>
                                    <TextField
                                        id="firstName"
                                        variant="outlined"
                                        margin="normal"
                                        label="First Name"
                                        name="fName"
                                        autoComplete="fname"
                                        required
                                        fullWidth
                                        autoFocus
                                        value={fullName.fNmae}
                                        onChange={handleFormFieldChange}
                                    />
                                    <TextField
                                        id="lastName"
                                        variant="outlined"
                                        margin="normal"
                                        label="Last Name"
                                        name="lName"
                                        autoComplete="lname"
                                        required
                                        fullWidth
                                        autoFocus
                                        value={fullName.lName}
                                        onChange={handleFormFieldChange}
                                    />
                                    <TextField
                                        id="email"
                                        variant="outlined"
                                        margin ="normal"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        required
                                        fullWidth
                                        autoFocus
                                        onChange={handleFormFieldChange}
                                    />
                                    <TextField
                                        id="password"
                                        variant="outlined"
                                        margin ="normal"
                                        name="password"
                                        label="Password"
                                        type="password"
                                        required
                                        fullWidth
                                        autoFocus
                                        onChange={handleFormFieldChange}
                                        error={ password !== password2 }
                                        helperText={password !== password2 ? "Password do not match": null}
                                    />
                                    <TextField
                                        id="password2"
                                        variant="outlined"
                                        margin ="normal"
                                        name="password2"
                                        label="Confirm Password"
                                        type="password"
                                        required
                                        fullWidth
                                        autoFocus
                                        onChange={handleFormFieldChange}
                                        error={password !== password2}
                                        helperText={password !== password2 ? "Password do not match" : null}
                                    />
                                </div>
                                <div className="flex items-center justify-between">
                                    <Button
                                        className="px-4 py-2"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Sign Up
                                    </Button>
                                    <Link href="/login" variant="body2">Already have an account?</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SignUp;