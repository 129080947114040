import React from "react";
import finBoard from "./svg/images/finBoard.svg";
import '@lottiefiles/lottie-player';


//TODO: make a better home page
function Home(props) {
    return (
        <div>
            <section className="colored-section" id="title">
                <div className="container-fluid">
                    {/* <!-- Title --> */}
                    <div className="row">
                        <div className="col-lg-6">
                            <h1 className="big-heading">Bringing personal finance to the next level.</h1>
                            <a href='signup'><button type="button" className="btn btn-dark btn-lg download-button">Get Started  <span className="fas fa-arrow-right"></span></button></a>
                        </div>
                        <div className="col-lg-6 img-container">
                            <img className="main-img" src={finBoard} alt="financial-board" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="white-section" id="product">
                <h2 className="section-heading">Product Offering</h2>
                <p>Need based assistance.</p>

                {/* <!-- Adding cards to features--> */}
                <div className="row">
                    <div className="feature-column col-lg-4 col-md-6">
                        <div className="card">
                            <div className="card-header feature-card">
                                <h3>Budget Management</h3>
                            </div>
                        </div> 
                    </div>
                    
                    <div className="feature-column col-lg-4 col-md-6">
                        <div className="card">
                            <div className="card-header feature-card">
                                <h3>Portfolio Tracker</h3>
                            </div>
                        </div>
                    </div>

                    <div className="feature-column col-lg-4">
                        <div className="card">
                            <div className="card-header feature-card">
                                <h3>OpEx Analytics</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* {Carousel Section} */}
            <section className="colored-section" id="carousel-expansion">
                {/* <!-- Implementing Carousel --> */}
                <div id="carousel-expansion-carousel" className="carousel slide" data-ride="true" data-pause="hover" interval='10000'>
                    <div className="carousel-inner">
                        <div className="carousel-item container-fluid">
                            <h2 className="carousel-expansion-text">Tracker Tabby</h2>
                            <em>Our portfolio platform Tabby that lets you add all your portfolio holdings in one place. Here you can see all the relevant information for a great investor like yourself.</em>        
                            {/* <div className="carousel-expansion-img">
                                <lottie-player id="tabby" className="carousel-expansion-img max-w-lg max-h-sm justify-center" loop autoplay
                                    src="https://assets7.lottiefiles.com/packages/lf20_tdfwofh5.json"> 
                                </lottie-player>
                            </div> */}
                        </div>
                        <div className="carousel-item active container-fluid">
                            <h2 className="carousel-expansion-text">Analytical Abyssinian</h2>
                            <em>Our predictive analytics platform Abyssinian helps you better understand where your investments are headed so you can take profits when necessary or buy more at the best moment.</em>        
                            {/* <div className="carousel-expansion-img">
                                <lottie-player id="abyssinian" className="carousel-expansion-img max-w-md justify-center" loop autoplay
                                    src="https://assets1.lottiefiles.com/packages/lf20_o1fx9ibz.json">
                                </lottie-player>
                            </div> */}
                        </div>
                        <div className="carousel-item container-fluid">
                            <h2 className="carousel-expansion-text">Management Mau</h2>
                            <em>Our monthly budget platform Mau lets you input all your spending so you can see exactly where your money is going and plan accordingly.</em>        
                            {/* <div className="carousel-expansion-img">
                                <lottie-player id="mau" className="carousel-expansion-img max-w-sm h-10 justify-center" loop autoplay
                                    src="https://assets5.lottiefiles.com/packages/lf20_gI9JEp.json">
                                </lottie-player>
                            </div> */}
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carousel-expansion-carousel" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carousel-expansion-carousel" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
            </section>
        </div>);
}

export default Home;