// react
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './components/App';

// tailwindcss
import './css/tailwind.css';

// redux
import { combineReducers, compose, createStore, applyMiddleware } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import {authReducer} from './components/auth-context/authReducer';

// Thunk wraps the dispatch actions into custom functions which are available with the mapDispatchToProps
const reducer = combineReducers({ auth: authReducer });
const composeEnhanced = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhanced(applyMiddleware(thunk))); // We are using thunk, because it allows delaying the dispatch actions

const root = createRoot(document.getElementById('root')); // createRoot(container!) if you use TypeScript
root.render(
    <React.StrictMode>
        <Provider store={store}> 
            <App />
        </Provider>
    </React.StrictMode>,
);
