import React from 'react';

// import v_photo from 'whereeverthatis'
// import k_photo from 'whereeverthatis'
// import a_photo from 'whereeverthatis'
// import p_photo from 'whereeverthatis'


const TeamMember = ({ name, photo, bio }) => {
  return (
    <div className="team-member">
      <img src={photo} alt={`${name}'s headshot`} />
      <h3>{name}</h3>
      <p>{bio}</p>
    </div>
  );
};

const LeadershipTeam = () => {
  const team = [
    {
      name: 'Vincenzo Scotto Di Uccio',
      photo: '',
      bio: 'leeyafoo',
    },
    {
      name: 'Ken Trinh',
      photo: '',
      bio: 'leeyafoo',
    },
    {
      name: 'Ansel Li ',
      photo: '',
      bio: 'leeyafoo',
    },
    {
        name: 'Preston Yun',
        photo: '',
        bio: 'leeyafoo',
    },
];

  return (
    <section className='colored-section'  id="title">
    <div className="leadership-team">
      <h1>Meet Our Leadership Team</h1>
      <p>Our company's mission is  share financial literacy for all. 
        We want to create a community of amatuers and professionals alike that will work together to build a financial community that supports the highest community posts.</p>
      <div className="team-members">
        {team.map((member, index) => (
          <TeamMember key={index} {...member} />
        ))}
      </div>
    </div>
    </section>
  );
};

export default LeadershipTeam;