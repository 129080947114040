/**
 * Contains Auth Actions to be dispatched. These perform two kinds of things:
 * 1) Return Action Objects:
 *      a) Simply Return an Action Object
 *      b) Perform some action and then return an Action Object
 * 2) Return A Dispatch(Action) combination:
 *      a) Perform an action then return a Dispatch(Action) combination
 * This Dispatch(Action) could be used by some other function to dispatch action to the store      
 */ 
import axios from 'axios';
import * as actionTypes from './authActionTypes';
import * as settings from '../../settings';

const SESSION_DURATION = settings.SESSION_DURATION


/**
 * Auth Start Action
 * Activate at the start the authentication process
 * @returns: auth start action object 
 */
export const authStart = () => {
    return { type: actionTypes.AUTH_START }
}


/**
 * Auth Success Action
 * Activate when user is successfully login
 * @param {*} token: system generated authentication value
 * @returns: auth success action object with the token
 */
export const authSuccess = (token) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token
    }
}


/**
 * Auth Fail Action
 * Activate when user fail to login
 * @param {*} error: system generated error message
 * @returns: auth fail action
 */
export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}


/**
 * Auth Logout Action
 * Log the user out of the application
 * Clear their random authentication token from the local storage 
 * @returns: auth logout action
 */
export const authLogout = () => {
    const token = localStorage.getItem('token');
    if (token === undefined) {
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('userid');
    } else {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('userid');
    }
    return { type: actionTypes.AUTH_LOGOUT };
}


/**
 * Auth Check Timmer Action
 * Set a timer, which would automatically logout the user after a specified time
 * @param {*} expirationTime: time in milliseconds
 * @returns: dispatch a logout action when the time is reach
 */
export const authCheckTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(authLogout());
        }, expirationTime)
    }
}


/**
 * Auth Login Action
 * Log the user in using the provided email & password combination
 * @param {*} loginPayload: user string of email and password
 * @returns: dispatch a login user function
 */
export const authLogin = ( loginPayload ) => {
    return dispatch => {
        axios.post(`${settings.API_SERVER}/api/users/login`, loginPayload)
            .then(res => {
                const success = res.data.success;
                if (success) {
                    const token = res.data.token;
                    const userid = res.data.userid;
                    const expirationDate = new Date(new Date().getTime() + SESSION_DURATION);
                    localStorage.setItem('token', token);
                    localStorage.setItem('expirationDate', expirationDate);
                    localStorage.setItem('userid', userid);
                    dispatch(authSuccess(token));
                    dispatch(authCheckTimeout(SESSION_DURATION));
                } else { 
                    // user has not confirm email address
                    alert(res.data.msg);
                    let userId = res.data.userid;
                    let email = res.data.email;
                    
                    axios.post(`${settings.API_SERVER}/api/emailTransporter/server_send`, {
                        "id": userId,
                        "email": email.toLowerCase()
                    })
                    .then(data=>{console.log(data.data.msg);})
                    .catch(err=>{console.log(err);})

                    dispatch(authFail(res.data.msg)); 
                }
            })
            .catch(err => {
                alert("User authentication is not Correct! Please check your email and password!");
                dispatch(authFail(err));
            });
    }
}


/**
 * Check the current login state of the user
 * If the user somehow lost their token, log them out
 * If the user exceed the allotted time, log them out
 * Else update the timmer
 * @returns dispatch an auth action according to user state while using the app
 */
export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if ((token === null) || (token === undefined)) {
            dispatch(authLogout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(authLogout());
            } else {
                dispatch(authSuccess(token));
                dispatch(authCheckTimeout(expirationDate.getTime() - new Date().getTime()));
            }
        }
    }
}
