import React, { useState, useEffect } from "react";
import { Button, Box, Card, CardContent, Paper,
        Table, TableBody, TableContainer, 
        TableHead, TableRow, TextField} from "@mui/material";
import axios from 'axios';
import * as settings from '../../../settings';

import Entry from "../page_templates/Entry";


function Budget(props) {
    // Initialize state variables
    const [income, setIncome] = useState([]);
    const [expense, setExpense] = useState([]);
    const [itemSetIncome, setItemSetIncome] = useState({
        item: null,
        price: null
    });
    const [itemSetExpense, setItemSetExpense] = useState({
        item: null,
        price: null
    });
    const [totalIncome, setTotalIncome] = useState(0.00);
    const [totalExpense, setTotalExpense] = useState(0.00);
    const [net, setNet] = useState(0.00);

    // update income/expense card every time their state is change
    useEffect(() => {
        updateCard();
    }, [income]);
    useEffect(() => {
        updateCard(false);
    }, [expense]);

    /**
     * function to update expense card once the state on client side
     * @return update itemSet State
     */
    const updateCard = (isIncome=true) => {
        const userid = localStorage.getItem("userid")
        axios.get(`${settings.API_SERVER}/api/budgets/item`,
            { params: { userid: userid } }
        ).then(response => {
            //set all data to data
            let dataList = Object.values(response.data);
            // split data on type (income/expense)
            if (isIncome===true) {
                let incomeList = [];
                dataList[0].forEach(item => {
                    if (item.type === "income") {
                        incomeList.push(item);
                    }
                });
                setIncome(incomeList);
                setTotalIncome(calcTotal(income));
                calcNet(totalIncome, totalExpense);
            } else {
                let expenseList = [];
                dataList[0].forEach(item => {
                    if (item.type === "expenses") {
                        expenseList.push(item);
                    }
                });
                setExpense(expenseList);
                setTotalExpense(calcTotal(expense));
                calcNet(totalIncome, totalExpense);
                }
        }).catch (err => console.log(err));
    }

    /**
     * Function to calculate the total price for a list of price
     * @param {*} priceList 
     * @returns 
     */
    const calcTotal = (priceList) => {
        let total = 0;
        priceList.forEach(item => {
            total += Number(item.price);
        });
        return total.toFixed(2);
    }

    /**
     * Function to compute the total differences 
     * between income/expense
     * @param {*} income 
     * @param {*} expense 
     */
    const calcNet = (income, expense) => {
        let diff = income - expense;
        setNet(diff.toFixed(2));
    }

    /**
     * function to update the ItemSetState based on identifier
     * @param {*} event: holds DOM state id, name, value 
     */
    const handleItemSetChange = (event) => {
        console.log(event.target.id);
        let identifier = event.target.id;
        if (identifier === 'income') {
            setItemSetIncome({
                ...itemSetIncome,
                [event.target.name]: event.target.value,
            });
        }
        else {
            setItemSetExpense({
                ...itemSetExpense,
                [event.target.name]: event.target.value,
            });
        }
    }

    /**
     * Function to handle the 
     * @param {*} event 
     * @param {type, set} inputparams: holds data to be push to userid database 
     */
    const saveToMongo = (event, {type, set}) => {
        event.preventDefault();
        const userid = localStorage.getItem("userid");
        if (set.item === "" || set.price === null) {
            alert("input fields are empty")
        } else {
            // Generate a post request to the database to store the user's data
            axios.post(`${settings.API_SERVER}/api/budgets/item`, {
                userid: userid,
                name: set.item,
                price: set.price,
                type: type
            }).then(response => {
                //get all data from the user
                //update the current state
                updateCard();
                updateCard(false);

                //reset the item state so that it is ready to accept new item
                setItemSetIncome({ item: "", price: 0 });
                setItemSetExpense({ item: "", price: 0 });
            }).catch(err => console.log(err));
        }
    }


    /**
     * Function to delete an item on a list
     * Update the database as needed
     * @param {*} itemID: id of the item to be deleted 
     */
    const deleteItem = (itemID) => {
        //set up delete input params
        const userid = localStorage.getItem("userid");
        const id = itemID;
        axios.delete(`${settings.API_SERVER}/api/budgets/item`, {
            params: {
                userid: userid,
                id: id,
            }
        }).then(response => {
            console.log(response);
            //update the current state
            updateCard();
            updateCard(false);
        });
    }

    return(
        <>
            <section className="colored-section" id="title">
                <div className="container-fluid">
                    <div className="flex flex-wrap justify-center mt-20">
                        <div className="col-lg-4 w-full max-w-sm">
                            <Card>
                                <CardContent>
                                    <Card>Monthly Income</Card>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <th>Item</th>
                                                    <th>Price</th>
                                                    <th>Menu</th>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {income !== [] ? income.map((testItem) => (
                                                    <Entry
                                                        key={testItem._id}
                                                        id={testItem._id}
                                                        item={testItem.name}
                                                        cost={testItem.price}
                                                        onClicked={deleteItem}
                                                    />
                                                )) : null}
                                                <TableRow>
                                                    <th><b>Total</b></th>
                                                    <td>{"$ " + totalIncome}</td>
                                                    <td>{null}</td>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box mt={4} alignItems="center">
                                        <TextField
                                            id="income"
                                            label="Item Name"
                                            value={itemSetIncome.item}
                                            onChange={handleItemSetChange}
                                            name="item"
                                        />
                                        <TextField
                                            id="income"
                                            label="Item Price"
                                            value={itemSetIncome.price}
                                            onChange={handleItemSetChange}
                                            name="price"
                                        />
                                        <Button
                                            component={Card}
                                            type="submit"
                                            name="income"
                                            value="add"
                                            onClick={(e) => saveToMongo(e, { type: "income", set: itemSetIncome })}
                                        >Add</Button>
                                    </Box>
                                </CardContent>
                            </Card>
                            <br></br>
                            <Card>
                                <CardContent>
                                    <Card>Monthly Expenses</Card>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <th>Item</th>
                                                    <th>Price</th>
                                                    <th>Menu</th>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {expense !== [] ? expense.map((testItem) => (
                                                    <Entry
                                                        key={testItem._id}
                                                        id={testItem._id}
                                                        item={testItem.name}
                                                        cost={"$ " + testItem.price.toFixed(2)}
                                                        onClicked={deleteItem}
                                                    />
                                                )) : null}
                                                <TableRow>
                                                    <th><b>Total</b></th>
                                                    <td>{"$ " + totalExpense}</td>
                                                    <td>{null}</td>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box mt={4} alignItems="center">
                                        <TextField
                                            id="expense"
                                            label="Item Name"
                                            value={itemSetExpense.item}
                                            onChange={handleItemSetChange}
                                            name="item"
                                        />
                                        <TextField
                                            id="expense"
                                            label="Item Price"
                                            value={itemSetExpense.price}
                                            onChange={handleItemSetChange}
                                            name="price"
                                        />
                                        <Button
                                            component={Card}
                                            type="submit"
                                            name="expenses"
                                            value="add"
                                            onClick={(e) => saveToMongo(e, { type: "expenses", set: itemSetExpense })}
                                        >Add</Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </div>
                        <div className="col-lg-4 w-full max-w-sm">
                            <Card>
                                <CardContent>
                                    <Card>Net Income</Card>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="customized table">
                                            <TableBody>
                                                <TableRow>
                                                    <th><b>Total</b></th>
                                                    <td>{"$ " + net}</td>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Budget;