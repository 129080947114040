/**
 * Setting up an initial state reducer function to manage state between other components
 * @inisialState : Initial Login State When Page is Access
 * @authReducer : reducer function that take in the initialState, and an auth action,
 *                The function will then update the initialState it accordingly
 */

//holds the global state change
import * as actionTypes from './authActionTypes';


/**
 * Initial State
 */
export const initialState = {
    error: null,
    token: null,
    loading: false
}


/**
 * Function to update state with new values
 * @param {*} oldObject 
 * @param {*} updatedProperties 
 * @returns updated parameters wto the previous state
 */
const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
}


/**
 * Reducer Functions to change the stored state
 * Apply a specific action as needed
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
const authStartReducer = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    });
}


const authSuccessReducer = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        token: action.token
    });
}


const authFailReducer = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}


const authLogoutReducer = (state, action) => {
    return updateObject(state, {
        token: null
    });
}


/**
 * main Reducer Function
 * @param {*} state: initialState
 * @param {*} action 
 * @returns switch cases to chain action to a specific reducer function
 */
export const authReducer = (state = initialState, action) => {
    // console.log(action, state);
    switch (action.type) {
        case actionTypes.AUTH_START: return authStartReducer(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccessReducer(state, action);
        case actionTypes.AUTH_FAIL: return authFailReducer(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogoutReducer(state, action);
        default: return state;
    }
}