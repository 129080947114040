//Render by react when user click on the email
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";
import * as settings from '../../settings';

function Confirm(props) {
    const { id } = useParams();
    const [confirming, setConfirming] = useState(true);

    // loading state to let the user know that we're trying to confirm them :D
    // enable component did mount -> call mongo on user _id
    useEffect(() => {
        axios.get(`${settings.API_SERVER}/api/users/confirm/${id}`)
             .then(data => {
                setConfirming(false);
                console.log(confirming);
                alert(data.data.msg);
             })
             .catch(err=> console.log(err));
    }, []);

    return (
        <div>
            <h1>YOUR EMAIL HAVE BEEN CONFIRMED!</h1>
        </div>
    );
}
export default Confirm;